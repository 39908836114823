import React, { useState, useRef, useEffect } from 'react';
import { IoEllipsisHorizontalOutline } from "react-icons/io5";
import { EllipsesButton } from './EllipsesButton';


function UserConversations({ setSelectedConversation, uid }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpened, setModalOpened] = useState(false);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [hoveredSessionId, setHoveredSessionId] = useState(null);



    const archiveConversation = async (sessionId) => {
        try {
            const response = await fetch(`/api/telemetry/archive`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sessionId }),
            });
            if (response.ok) {
                setConversations(conversations.map(conv =>
                    conv.sessionId === sessionId ? { ...conv, archived: true } : conv
                ));
            } else {
                console.error('Failed to archive the conversation:', await response.text());
            }
        } catch (error) {
            console.error('Error archiving the conversation:', error);
        }
    };

    useEffect(() => {
        async function fetchConversations() {
            try {
                const response = await fetch(`/api/telemetry/threads?userId=${uid}`);
                if (response.ok) {
                  const data = await response.json();
                  console.log("conversations", data)
                  setConversations(data.data);
              } else {
                  console.error('Failed to fetch conversations');
              }
          } catch (error) {
              console.error('There was an error fetching the conversations:', error);
          } finally {
              setLoading(false);
          }
        }

        if (uid != null){
            fetchConversations();
        }


    }, [uid]);


    const handleArchiveDelete = async (sessionId) => {
        await archiveConversation(sessionId);
    };

    return (
      <div className="flex flex-col h-full">
          <div>
                {conversations.filter(conv => !conv.archived).map(conv => (
                    <div
                        key={conv.sessionId}
                        className="p-2 cursor-pointer hover:text-bluelogo rounded-lg text-sm font-medium text-textGray hover:text-bluelogo relative"
                        onMouseEnter={() => setHoveredSessionId(conv.sessionId)}
                    >
                        <div className="flex items-center space-x-3 rounded-xl">
                            <div className="flex-grow inline">
                                <a className='' href={`/threads/${conv.sessionId}`}>
                                {conv.topic.length > 20 ? `${conv.topic.substring(0, 22)}` : conv.topic}
                                </a>

                                {hoveredSessionId === conv.sessionId && (
                                <EllipsesButton sessionId={conv.sessionId} handleArchiveDelete={handleArchiveDelete} />
                                )}
                            </div>
                        </div>
                    </div>
                ))}
          </div>

          {/* <Modal
            opened={modalOpened}
            onClose={() => setModalOpened(false)}
            title="Conversation Actions"
          >
            <Button onClick={() => handleArchiveDelete('archive')}>Archive</Button>
            <Button onClick={() => handleArchiveDelete('delete')}>Delete</Button>
            <Button onClick={() => handleArchiveDelete('share')}>Share</Button>
          </Modal> */}
      </div>
  );
}

export default UserConversations;